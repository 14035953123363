<template lang="pug">
.map-wrapper
	.map-info.sf-card
		.info-header
			svg(width="114", height="24", viewBox="0 0 114 24", fill="none", xmlns="http://www.w3.org/2000/svg")
				g(clip-path="url(#logo)")
					path(d="M41.384 12.2325C40.3906 17.8619 36.8589 18.5815 33.8704 18.5815C30.9212 18.5815 28.2033 16.5643 28.9716 12.2038C29.5726 8.79659 31.611 5.64361 36.5677 5.88347C40.025 6.05157 42.0593 8.40298 41.384 12.2325ZM35.9812 8.31688C34.8948 8.31688 33.6618 8.8663 32.9514 12.2099C32.1624 15.9308 33.7362 16.1686 34.5003 16.1686C35.1943 16.1686 36.671 15.7934 37.4062 12.2304C37.9225 9.73962 37.5136 8.31688 35.9812 8.31688Z", fill="#FFFFFF")
					path(
						d="M48.9094 18.0981L48.7235 18.2826C47.9841 18.42 47.1126 18.5348 46.6417 18.5348C44.8945 18.5348 42.3314 18.2724 43.4157 13.8996C44.1489 10.9414 44.6755 8.73143 44.6755 8.73143L43.0563 8.77653L42.918 8.59203L43.5892 6.27341L43.7503 6.15861H45.2765L45.9478 3.19628L46.1088 3.03638C47.8437 2.64687 48.3063 2.53206 49.9028 2.0708L50.0412 2.20815L49.023 6.20371L51.3815 6.15861L51.4745 6.31852C51.1502 7.28204 51.0573 7.53625 50.7578 8.61458L50.5719 8.77653L48.4447 8.73143C48.4447 8.73143 48.013 10.5129 47.4079 12.9935C46.7532 15.675 47.0981 15.5397 47.7528 15.7959C48.3125 16.0153 49.0457 15.7344 49.4629 15.5971L49.6013 15.7119L48.9094 18.0981Z",
						fill="#FFFFFF"
					)
					path(
						d="M57.2817 7.00906C57.8827 6.59495 58.8782 5.90613 60.3817 5.90613C62.6948 5.90613 64.2438 7.62817 64.2438 10.5434C64.2438 12.1957 63.6655 14.1474 62.4635 15.5947C60.1731 18.3725 57.2817 18.35 54.8302 18.35L53.5125 23.838L53.3514 23.9528C52.8186 23.9528 52.3105 23.9302 51.7777 23.9302C50.9908 23.9302 50.2969 23.9753 49.4418 23.9999L49.3262 23.838C49.3262 23.838 50.1399 21.4045 51.156 17.2204C52.2217 12.8292 53.2151 7.68558 53.3039 6.64005L53.465 6.47809L57.4201 5.83643L57.5584 5.99633L57.2817 7.00906ZM55.4085 15.8017C56.5051 15.9309 57.6989 15.5721 58.5085 14.9059C59.4337 14.1248 60.266 12.5176 60.266 10.773C60.266 8.84386 59.0165 8.61425 58.5539 8.61425C57.8827 8.61425 57.3746 8.95866 56.8893 9.28052L55.4085 15.8017Z",
						fill="#FFFFFF"
					)
					path(d="M0 11.3409C0 5.08209 5.10747 0.00615017 11.4107 0V4.09806C7.38755 4.10626 4.12852 7.34535 4.12852 11.3409C4.12852 15.3406 7.39581 18.5838 11.4252 18.5838C15.4298 18.5838 18.7219 15.3713 18.7219 11.3143L22.8504 11.3163C22.8504 17.6038 17.7347 22.6818 11.4252 22.6818C5.11573 22.6798 0 17.6038 0 11.3409Z", fill="#FFFFFF")
					path(d="M6.38281 11.3409C6.38281 8.56307 8.65257 6.31006 11.451 6.31006C14.2495 6.31006 16.5193 8.56307 16.5193 11.3409C16.5193 14.1187 14.2495 16.3717 11.451 16.3717C8.65257 16.3697 6.38281 14.1187 6.38281 11.3409Z", fill="#FFFFFF")
					path(d="M14.9668 4.96929C14.9668 3.3764 16.2679 2.08691 17.8706 2.08691C19.4753 2.08691 20.7744 3.37845 20.7744 4.96929C20.7744 6.56014 19.4753 7.85167 17.8706 7.85167C16.2659 7.85167 14.9668 6.56014 14.9668 4.96929Z", fill="#FFFFFF")
					path(
						d="M79.1383 12.4604C78.8967 13.4178 78.6571 14.3731 78.4154 15.3469C78.2254 16.132 78.054 16.9008 77.8826 17.688L77.7793 17.8254C77.5893 17.9115 77.4014 17.9955 77.2114 18.0652C76.8665 18.2026 75.8008 18.5265 74.3551 18.5265C73.2708 18.5265 72.2051 18.3728 71.189 17.7926C69.5368 16.8537 69.0039 15.1111 69.0039 13.4711C69.0039 10.3796 71.2406 5.95557 76.1787 5.95557C76.6951 5.95557 77.2114 6.00682 77.7442 6.12572C78.7418 6.33073 79.2065 6.62183 79.7579 6.96214L79.793 7.1159L79.3283 8.56734L79.1569 8.61859C78.8306 8.25983 78.5889 7.98717 77.9177 7.74732C77.3498 7.54231 76.7653 7.45621 76.1622 7.45621C75.7326 7.45621 75.2845 7.50746 74.8549 7.64481C73.8388 7.95232 72.8577 8.68624 72.2051 9.60877C71.2923 10.8736 70.9494 12.5301 70.9494 13.5039C70.9494 14.3915 71.2076 15.2464 71.6372 15.7938C72.3084 16.6487 73.341 16.9726 74.4253 16.9726C75.303 16.9726 75.7512 16.8188 76.2324 16.6487L76.4555 15.7938C76.8169 14.1537 76.8851 13.7601 77.0916 12.4809L77.1949 12.3784L79.0701 12.3435L79.1383 12.4604Z",
						fill="#FFFFFF"
					)
					path(
						d="M81.9767 18.1471L81.8383 18.266L80.0477 18.3009L79.9609 18.1984L80.597 15.7034L80.975 14.0798C81.4913 11.876 81.5595 11.4332 81.8362 9.65578L81.956 9.53688C82.7987 9.43438 82.9887 9.41797 83.8148 9.24577L83.9015 9.36467L83.5236 10.8674C83.6619 10.6275 83.7982 10.4061 83.9717 10.2011C84.5397 9.49998 85.2274 9.15967 85.7623 9.15967C86.037 9.15967 86.2621 9.24577 86.5533 9.36467L86.605 9.51843L85.9338 11.1933L85.7623 11.2446C85.5207 11.1072 85.3328 11.0047 85.0395 11.0047C84.5748 11.0047 84.1783 11.2446 83.8519 11.5849C83.284 12.165 83.0258 13.1573 82.6479 14.8465L82.2865 16.5378L81.9767 18.1471Z",
						fill="#FFFFFF"
					)
					path(
						d="M90.0304 18.4732C87.742 18.4732 86.4863 17.2944 86.4863 14.9717C86.4863 12.8868 87.6057 9.19873 91.3563 9.19873C93.6116 9.19873 94.952 10.4452 94.952 12.649C94.952 13.2476 94.8487 13.8626 94.6938 14.4592C94.3138 15.927 93.2646 18.4732 90.0304 18.4732ZM93.1097 12.4604C93.1097 10.7342 91.9903 10.4452 91.3542 10.4452C88.6528 10.4452 88.3079 14.6129 88.3079 15.2443C88.3079 16.6117 89.134 17.2083 90.0634 17.2083C91.9036 17.2083 92.5934 15.3304 92.9032 13.9282C93.023 13.4341 93.1097 12.938 93.1097 12.4604Z",
						fill="#FFFFFF"
					)
					path(
						d="M103.04 18.1475L102.92 18.25L101.232 18.3176L101.146 18.1987L101.352 17.3623C100.594 18.0286 99.4253 18.4386 98.5124 18.4386C97.4116 18.4386 95.9824 17.9097 95.9824 16.0974C95.9824 15.4147 96.1373 14.7649 96.412 13.5184L96.8085 11.8435C97.0832 10.6812 97.1183 10.4598 97.2898 9.53723L97.4095 9.41833L99.1299 9.35068L99.2167 9.45318C99.0783 10.0333 98.3554 12.9711 98.2708 13.297C98.1675 13.7234 97.8742 15.0232 97.8742 15.587C97.8742 16.8334 98.8904 17.0056 99.3034 17.0056C100.439 17.0056 101.197 16.3906 101.643 16.0154L102.298 13.1125C102.728 11.1486 102.798 10.7898 103.021 9.54338L103.159 9.42448L104.898 9.33838L104.966 9.45728L103.969 13.6599L103.556 15.5213L103.04 18.1475Z",
						fill="#FFFFFF"
					)
					path(
						d="M108.444 10.0679C109.098 9.65786 109.821 9.23145 110.903 9.23145C112.556 9.23145 114.001 10.4779 114.001 12.6468C114.001 13.8933 113.553 15.1746 112.849 16.0807C111.129 18.2845 108.512 18.2845 107.601 18.2845C107.275 18.2845 106.93 18.2845 106.603 18.2681C106.242 19.822 106.155 20.1992 105.777 22.2657L105.658 22.3518L103.85 22.3682L103.764 22.2657L104.71 18.389L105.054 17.0401C105.381 15.5702 105.726 14.0839 106.035 12.6161C106.345 11.0949 106.432 10.4984 106.568 9.64351L106.688 9.541L108.53 9.2499L108.617 9.3688L108.444 10.0679ZM106.895 16.9684C107.136 16.9848 107.343 17.0196 107.601 17.0196C110.544 17.0196 112.126 14.8506 112.126 12.8518C112.126 11.2282 111.11 10.648 110.164 10.648C109.08 10.648 108.409 11.2631 108.15 11.5029L106.895 16.9684Z",
						fill="#FFFFFF"
					)
				defs
					clipPath#logo
						rect(width="114", height="24", fill="#FFFFFF")
		.info-content
			template(v-if="isMobile")
				label#countries-select-box-label.sr-only(for="countries-select-box") Please choose a country
				VueSelect#countries-select-box(label-id="countries-select-box-label", v-model="selectedBank", placeholder="Please choose a country!")
					VueSelectOption(v-for="(bank, index) in banks", :text="bank", :key="index", :index="index", :value="bank", :label-id="`countries-select-box-${index}`")
				a.sf-link( v-if="selectedWebsite", :href="selectedWebsite", target="_blank") Visit website
					svg(width="16", height="16", viewBox="0 0 16 16", fill="none", xmlns="http://www.w3.org/2000/svg")
						path(d="M6 2L4 2C2.89543 2 2 2.89543 2 4L2 12C2 13.1046 2.89543 14 4 14L12 14C13.1046 14 14 13.1046 14 12L14 10M7 9L14 2M14 2L9 2M14 2L14 7", stroke="#52AE30", stroke-linecap="round", stroke-linejoin="round")
			template(v-else)
				ul(v-if="selectedBank")
					li.sf-text-lead.sf--bold(v-for="(item, index) in banksByCountry" :key="index") {{ item.bank }}
						br
						a.sf-link(:href="item.website", target="_blank") Visit website
							svg(width="16", height="16", viewBox="0 0 16 16", fill="none", xmlns="http://www.w3.org/2000/svg")
								path(d="M6 2L4 2C2.89543 2 2 2.89543 2 4L2 12C2 13.1046 2.89543 14 4 14L12 14C13.1046 14 14 13.1046 14 12L14 10M7 9L14 2M14 2L9 2M14 2L14 7", stroke="#52AE30", stroke-linecap="round", stroke-linejoin="round")
				p.sf-text-lead.sf--bold(v-else) Please choose a country!
		p.sf-text(v-if="!(countries && countries.length)") Countries JSON cannot be found!
	.map-content(:class="selectedCountry")
		svg(width="1120", height="700", viewBox="0 0 1120 700", fill="none", xmlns="http://www.w3.org/2000/svg")
			g.country(v-for="(countryItem, index) in countries", :key="countryItem.country", :id="countryItem.country", :class="{ active: selectedCountry == countryItem.country }")
				a(href="#", @click.prevent="selectBankByCountry(countryItem.country)")
					use.pin(href="#pin", :x="countryItem.pin.default.x", :y="countryItem.pin.default.y")
					use.pin-active(href="#pin_active", :x="countryItem.pin.active.x", :y="countryItem.pin.active.y")
					text.text(:x="countryItem.text.x", :y="countryItem.text.y") {{ countryItem.country }}
			defs
				g#pin
					path(stroke="#FFF", fill="transparent", d="m13.188 32.679l0 0c-7.528 -1.548 -13.188 -8.202 -13.188 -16.179c0 -9.113 7.387 -16.5 16.5 -16.5c9.112 0 16.5 7.387 16.5 16.5c0 7.822 -5.593 15 -12.747 16.179m-7.065 0m0 0l1.945 4.934l0 0c0.561 1.423 2.575 1.423 3.135 0l1.984 -4.934")
					path(fill="#FFF", clip-rule="evenodd", fill-rule="evenodd", d="m16.39 5.581l0 3.86c-3.757 0.006 -6.8 3.059 -6.8 6.822c0 3.769 3.05 6.823 6.812 6.823c3.74 0 6.812 -3.027 6.812 -6.848l3.855 0.003c0 5.923 -4.776 10.706 -10.667 10.706c-5.891 0 -10.666 -4.783 -10.666 -10.684c0 -5.895 4.77 -10.676 10.654 -10.682z")
					path(fill="#FFF", clip-rule="evenodd", fill-rule="evenodd", d="m16.427 11.524c2.614 0 4.732 2.123 4.732 4.739c0 2.618 -2.118 4.738 -4.731 4.738c-2.613 0 -4.731 -2.12 -4.731 -4.738c0 -2.616 2.118 -4.739 4.73 -4.739z")
					path(fill="#FFF", clip-rule="evenodd", fill-rule="evenodd", d="m22.421 7.546c1.497 0 2.711 1.217 2.711 2.717c0 1.498 -1.214 2.715 -2.711 2.715c-1.497 0 -2.712 -1.217 -2.712 -2.715c0 -1.5 1.215 -2.717 2.712 -2.717z")
				g#pin_active
					path(fill="#FFF", d="m25.36901 41.545c9.501 -2.011 16.631 -10.445 16.631 -20.545c0 -11.598 -9.403 -21 -21 -21c-11.598 0 -21 9.402 -21 21c0 10.29 7.401 18.852 17.171 20.652c0.017 0.114 0.047 0.229 0.092 0.343l2.554 6.475c0.517 1.314 2.376 1.314 2.894 0l2.553 -6.475c0.059 -0.15 0.093 -0.301 0.105 -0.45z", clip-rule="evenodd", fill-rule="evenodd")
					path(fill="#006648", d="m20.85501 6.669l0 5.065c-4.93 0.009 -8.924 4.016 -8.924 8.955c0 4.946 4.003 8.955 8.94 8.955c4.909 0 8.941 -3.972 8.941 -8.988l5.06 0.004c0 7.773 -6.269 14.052 -14.001 14.052c-7.732 0 -13.999 -6.279 -13.999 -14.023c0 -7.738 6.26 -14.012 13.983 -14.02z", clip-rule="evenodd", fill-rule="evenodd")
					path(fill="#52AE30", d="m20.90501 14.469c3.431 0 6.21 2.786 6.21 6.221c0 3.435 -2.779 6.218 -6.209 6.218c-3.43 0 -6.21 -2.783 -6.21 -6.218c0 -3.435 2.78 -6.221 6.209 -6.221z", clip-rule="evenodd", fill-rule="evenodd")
					path(fill="#006648", d="m28.77201 9.248c1.965 0 3.558 1.598 3.558 3.565c0 1.968 -1.593 3.564 -3.558 3.564c-1.965 0 -3.559 -1.596 -3.559 -3.564c0 -1.967 1.594 -3.565 3.559 -3.565z", clip-rule="evenodd", fill-rule="evenodd")
				filter#filterDropShadow(filterUnits="userSpaceOnUse", color-interpolation-filters="sRGB")
					feFlood(flood-opacity="0", result="BackgroundImageFix")
					feColorMatrix(in="SourceAlpha", type="matrix", values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result="hardAlpha")
					feOffset(dy="4")
					feGaussianBlur(stdDeviation="2")
					feComposite(in2="hardAlpha", operator="out")
					feColorMatrix(type="matrix", values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0")
					feBlend(mode="normal", in2="BackgroundImageFix", result="dropShadow")
					feBlend(mode="normal", in="SourceGraphic", in2="dropShadow", result="shape")
</template>
<script>
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
import countriesApi from '../../api/countries-api';
import { VueSelect, VueSelectOption } from '@ui-common/vue-select';
import { isMobile } from '@ui-common/sf-js-utils';
import { COUNTRIES_SVG_DATA } from './constants';

export default defineComponent({
	mixins: [isMobile],
	components: {
		VueSelect,
		VueSelectOption
	},
	props: {
		defaultCountry: {
			type: String,
			required: true
		}
	},
	setup(props) {
		const selectedBank = ref(null);
		const countries = ref([]);

		const banks = computed(() => countries.value.map((item) => item.bank));
		const selectedWebsite = computed(() => (selectedBank.value ? countries.value.find((item) => item.bank === selectedBank.value).website : null));
		const selectedCountry = computed(() => (selectedBank.value ? countries.value.find((item) => item.bank === selectedBank.value).country : null));
		const banksByCountry = computed(() => (selectedBank.value ? countries.value.filter((item) => item.country === selectedCountry.value) : null));

		const getMapData = async () => {
			try {
				const response = await countriesApi.getJson();
				countries.value = response.data.map((countryData) => {
					const svgData = COUNTRIES_SVG_DATA.find((data) => data.name == countryData.country);
					return { ...countryData, pin: svgData.pin, text: svgData.text };
				});

				selectBankByCountry(props.defaultCountry);
			} catch (error) {
				console.log('Countries JSON cannot be found!');
			}
		};
		const selectBankByCountry = (country) => {
			selectedBank.value = countries.value.find((item) => item.country === country).bank;
		};

		onMounted(() => getMapData());

		return {
			banks,
			countries,
			selectedBank,
			selectedWebsite,
			selectedCountry,
			banksByCountry,
			selectBankByCountry,
			COUNTRIES_SVG_DATA
		};
	}
});
</script>
<style src="@ui-common/vue-select/dist/vue-select.css"></style>
<style lang="scss" scoped>
@import '@ui-common/sf-style-utils/lib/helpers/_colors';
@import '@ui-common/sf-style-utils/lib/helpers/_queries';

.map-wrapper {
	border-radius: 32px;
	height: 400px;
	margin-bottom: 32px;
	margin-left: -16px;
	margin-right: -16px;
	overflow: hidden;
	position: relative;

	@include media('>=tablet') {
		height: 700px;
		margin-left: auto;
		margin-right: auto;
	}

	@include media('>=tablet', '<desktop-lg') {
		overflow-x: auto;
		overflow-y: hidden;
	}

	@include media('>=desktop-lg') {
		max-width: 1120px;
	}
}

.map-info {
	left: 8px;
	min-width: 288px;
	padding-bottom: 24px;
	position: absolute;
	text-align: center;
	top: 8px;
	z-index: 1;

	@include media('>=tablet') {
		left: 32px;
		max-width: 320px;
		top: 32px;
		width: 320px;
	}

	@include media('>=tablet', '<desktop-lg') {
		position: sticky;
	}

	& p,
	& li {
		line-height: 24px;
		margin-bottom: 0;
	}

	& li:not(:last-child) {
		margin-bottom: 16px;
	}

	& .sf-link {
		align-items: center;
		display: inline-flex;
		font-size: 14px;
		gap: 4px;
		line-height: 16px;
	}

	.sf-select-wrapper:not(:last-child) {
		margin-bottom: 16px;
	}

	.sf-select {
		@include media('>=tablet') {
			display: none;
		}
	}
}

.info-header {
	background-color: $ui-brand;
	border-radius: 24px 24px 0 0;
	margin: -32px -16px 24px;
	padding: 24px;

	@include media('>=tablet') {
		border-radius: 32px 32px 0 0;
		margin-bottom: 32px;
	}

	@include media('>=tablet', '<desktop') {
		margin: -32px -24px 32px;
	}

	@include media('>=desktop') {
		margin: -32px -32px 32px;
	}

	& svg {
		vertical-align: top;
	}
}

.map-content {
	background: url('/static/otpgroup/img/countries.png') 0 0 no-repeat;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 1120px;

	@include media('<tablet') {
		transition: all 0.3s;

		/* Set center position of the countries here! */
		$countries: 234 -100 albania, 152 -39 bulgaria, 316 -15 croatia, 267 54 hungary, 77 48 moldova, 262 -32 montenegro, 151 32 romania, -101 298 russia,
			224 -10 serbia, 355 33 slovenia, 3 112 ukraine, -448 2 uzbekistan;

		@each $x, $y, $country in $countries {
			$x_op_val: if($x > 0, ' + #{$x}px', ' - #{-$x}px');
			$y_op_val: if($y > 0, ' + #{$y}px', ' - #{-$y}px');

			&.#{$country} {
				left: calc(50%#{$x_op_val});
				top: calc(50%#{$y_op_val});
			}
		}
	}

	@include media('>=tablet', '<desktop-lg') {
		left: 0;
		top: 0;
		transform: none;
	}

	svg {
		display: block;
	}
}

// svg classes
.country {
	.pin,
	.pin-active {
		cursor: pointer;
		filter: url(#filterDropShadow);
	}

	.pin {
		display: block;
	}

	.pin-active {
		display: none;
	}

	&.active .pin {
		display: none;
	}

	&.active .pin-active {
		display: block;
	}

	@include media('<tablet') {
		.pin,
		.pin ~ .text {
			display: none;
		}

		&.active .text {
			display: block;
		}
	}

	.text {
		fill: #fff;
		filter: url(#filterDropShadow);
		font-family: inherit;
		font-size: 10px;
		font-weight: 100;
		text-transform: capitalize;
	}
}
</style>
